import { useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import ProductsNavbar from '../Navbar/ProductsNavbar';
import { Typography, Divider, Row, Col, Carousel, Button } from 'antd';
import { productsData } from './ProductsData';

const { Title, Text } = Typography;

const ProductDetail = () => {
    const { productName } = useParams(); // Extracting the product name from URL params

    // Find the product details based on the product name
    const product = productsData
        .flatMap(category => category.items)
        .find(item => item.name === productName);

    // Function to render details grid in two columns
    const renderDetailsGrid = details => {
        const keys = Object.keys(details);
        const numberOfColumns = Math.ceil(keys.length / 2); // Calculate the number of columns needed

        const items = [];
        for (let i = 0; i < numberOfColumns; i++) {
            const columnItems = keys.slice(i * 2, i * 2 + 2); // Get two items for each column
            const columns = columnItems.map((key, index) => (
                <Col xs={24} md={12} key={key}>
                    <Title level={4} style={{ color: 'orange' }}>{key}</Title>
                    <ul style={{ fontSize: '15px', marginBottom: '15px' }}>
                        {details[key].map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Col>
            ));
            items.push(
                <Row gutter={[16, 16]} key={i}>
                    {columns}
                </Row>
            );
        }

        return (
            <div>
                {items}
            </div>
        );
    };

    // Function to render carousel with all product images
    const renderProductCarousel = () => {
        const imageCount = product.numberOfImages; // Get the number of images for the product
        const imagePaths = Array.from({ length: imageCount }, (_, index) => `/assets/${productName}/${index + 1}.png`);

        return (
            <Carousel autoplay effect="fade" style={{ background: '#ffa940' }}>
                {imagePaths.map((imagePath, index) => (
                    <div key={index}>
                        <img
                            style={{ width: '100%', height: '450px' }}
                            src={imagePath}
                            alt={`${index + 1}`}
                        />
                    </div>
                ))}
            </Carousel>
        );
    };

    const downloadPdf = () => {
        // Here, specify the path to the PDF file in your assets folder
        const pdfPath = `/assets/${productName}/Data Sheet.pdf`;
        window.location.href = pdfPath; // This will prompt the browser to download the PDF file
    };

    // Display the product details
    return (
        <>
            <ProductsNavbar />
            <div style={{ maxWidth: '700px', margin: '0 auto', padding: '0 20px' }}>
                <div style={{ textAlign: 'center' }}>
                    <Title style={{ color: 'orange' }}>
                        {product.name}
                    </Title>
                    <Text style={{ fontSize: '15px' }}>{product.shortDescription}</Text>
                </div>
                <Divider />
                {/* Render the product carousel */}
                {renderProductCarousel()}
                <Divider />
                <div style={{ marginBottom: '40px' }}>
                    {renderDetailsGrid(product.detailsGrid)}
                </div>
                <div style={{ display: 'flex', marginBottom: '40px' }}>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size={'large'} onClick={downloadPdf}>
                        Download Product Sheet
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
