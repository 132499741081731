  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
  import { ConfigProvider } from 'antd';
  import Home from './components/Home/Home.js';
  import About from './components/About/About.js';
  import Contact from './components/Contact/Contact.js';
  import Products from './components/Products/Products.js';
  import ProductDetail from './components/Products/ProductDetail.js';
import AppFooter from './components/Footer/Footer.js';

  const PageContainer = ({ children }) => {
    return <div className="page-container">{children}</div>;
  };

  const App = () => {
    return (
      <Router>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#FFA500',
              colorLinkActive: '#FFA500',
              // fontFamily: 'Helvetika',
              itemSelectedColor: '#FFA500'
            },
          }}
        >
          <PageContainer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:productName" element={<ProductDetail />} />
            </Routes>
            <AppFooter></AppFooter>
          </PageContainer>
        </ConfigProvider>
      </Router>
    );
  };

  export default App;
