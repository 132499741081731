import NavBarComponent from "../Navbar/Navbar";
import { Typography, Space, Divider } from "antd";
import { EnvironmentOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function Contact() {
    return (
        <>
            <NavBarComponent />
            <div style={{ position: 'relative' }}>
                <img src="/assets/contactUs2.jpg" alt="Contact Us" style={{ width: '100%', height: '580px', filter: 'blur(1px)' }} />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: 'white' }}>
                    <Title level={2} style={{ fontSize: '45px', color: 'orange' }}>
                        Contact Us
                    </Title>
                </div>
            </div>
            <div style={{ margin: '30px auto', maxWidth: '800px', textAlign: 'left' }}>
                <Title style={{ marginLeft: '30px', fontSize: '45px', color: 'orange' }}>DETAILS</Title>
                <div style={{ width: '75px' }}>
                    <Divider style={{ marginLeft: '30px', borderTop: "5px solid orange", width: 'fit-content' }} />
                </div>

                <div style={{ marginLeft: '30px'}}>
                    <Space direction="vertical" size="middle">

                        <div>
                            <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                            <Text level={3} style={{ color: 'orange', fontSize: '25px', justifyContent: 'center', }}>Address: </Text>
                            <Text style={{ fontSize: '20px' }}>Uniwork Spaces - PRO #430, 4th Floor, 5th A Cross Rd, HRBR Layout 2nd Block, Kalyan Nagar, Bengaluru, Karnataka 560043</Text>
                        </div>
                        <div>
                            <PhoneOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                            <Text level={3} style={{ color: 'orange', fontSize: '25px' }}>Phone Number: </Text>
                            <Text style={{ fontSize: '20px' }}>+91 9738818187</Text>
                        </div>
                        <div>
                            <MailOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                            <Text level={3} style={{ color: 'orange', fontSize: '25px' }}>Email: </Text>
                            <Text style={{ fontSize: '20px' }}>info@ecoampsolutions.com</Text>
                        </div>
                    </Space>
                </div>
                
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.158812428399!2d77.63673697518033!3d13.025556787294802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17a29b5bcdf7%3A0x2c8ace41c056ca5e!2sUniwork%20PRO!5e0!3m2!1sen!2sin!4v1702017692519!5m2!1sen!2sin" width="100%" height="600" title="Map" style={{ marginTop: '10px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}
