import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const items = [
    {
        label: (<Link to="/">Home</Link>),
        key: 'home',
    },
    {
        label: "Commercial & Industrial",
        key: 'Commercial and Industrial',
        children: [
            {
                label: (<Link to="/products/SG110CX">SG110CX</Link>),
                key: 'SG110CX',
            },
            {
                label: (<Link to="/products/SG125CX-P2">SG125CX-P2</Link>),
                key: 'SG125CX-P2',
            },
            {
                label: (<Link to="/products/SG33CX-P2">SG33CX-P2</Link>),
                key: 'SG33CX-P2',
            },
            {
                label: (<Link to="/products/SG50CX-P2">SG50CX-P2</Link>),
                key: 'SG50CX-P2',
            },
        ]
    },
    {
        label: "Monitoring Solutions",
        key: 'Monitoring Solutions',
        children: [
            {
                label: (<Link to="/products/COM100D">COM100D</Link>),
                key: 'COM100D',
            },
            {
                label: (<Link to="/products/EMU200A">EMU200A</Link>),
                key: 'EMU200A',
            },
            {
                label: (<Link to="/products/EyeM4">EyeM4</Link>),
                key: 'EyeM4',
            },
            {
                label: (<Link to="/products/EyeS4">EyeS4</Link>),
                key: 'EyeS4',
            },
            {
                label: (<Link to="/products/WiFi">WiFi</Link>),
                key: 'WiFi',
            },
            {
                label: (<Link to="/products/WiNet-S">WiNet-S</Link>),
                key: 'WiNet-S',
            },
            {
                label: (<Link to="/products/Zero-Export Device (CX series without CT)">DTSD1352-C/1(6)A</Link>),
                key: 'DTSD1352-C/1(6)A',
            },
            {
                label: (<Link to="/products/Zero-Export Device (RS_RT_Series)">DTSU666</Link>),
                key: 'DTSU666',
            },
        ]
    },
    {
        label: "Residential",
        key: 'Residential',
        children: [
            {
                label: (<Link to="/products/SG3.0RS-L">SG3.0RS-L</Link>),
                key: 'SG3.0RS-L',
            },
            {
                label: (<Link to="/products/SG15 20RT">SG15 20RT</Link>),
                key: 'SG15 20RT',
            },
        ]
    },
    {
        label: "Utility",
        key: 'Utility',
        children: [
            {
                label: (<Link to="/products/SG320HX">SG320HX</Link>),
                key: 'SG320HX',
            },
            {
                label: (<Link to="/products/SG320HX-20">SG320HX-20</Link>),
                key: 'SG320HX-20',
            },
        ]
    },
];

export default function ProductsNavbar() {
    const [current, setCurrent] = useState('mail');
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    return (
        <div>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={{backgroundColor: '#ffffff', fontSize: '20px', lineHeight: '80px', justifyContent: 'center', paddingRight: '100px', paddingLeft: '100px' }} />
        </div>
    );
}