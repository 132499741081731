import { Typography, Row, Col, Divider } from 'antd';
import NavBarComponent from '../Navbar/Navbar';
import '../../styles/about.css'
const { Title } = Typography;


export default function About() {
    return (
        <>
            <NavBarComponent></NavBarComponent>
            <img src={'/assets/aboutImage.png'} alt="About" className='aboutImage'></img>
            <div style={{ maxWidth: '1100px', margin: '0 auto', padding: '0 20px', marginBottom: '40px' }}>
                <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Title level={3} style={{ color: 'orange', fontSize: '40px' }}>
                            OUR STORY
                        </Title>
                        <div style={{ width: '100px' }}>
                            <Divider style={{ borderTop: "5px solid orange", width: 'fit-content' }} />
                        </div>
                        <Title level={4} >EXPLORE OUR RANGE OF SOLAR PV INVERTERS</Title>
                        <Title level={5} style={{ textAlign: 'justify' }}>
                            At Eco Amp Solutions, we take pride in being a trusted name in the solar energy industry, specializing in the distribution of high-quality Solar PV inverters. With a wealth of experience in solar installations, applications, products, and services, we have evolved into a leading dealer for Sungrow PV inverter manufacturer.
                        </Title>
                        <Title level={5} style={{ textAlign: 'justify' }}>
                            Our Commitment to Excellence: 
                            Expertise in Solar Installations: With years of hands-on experience in solar installations, our team at Eco Amp Solutions understands the unique challenges and opportunities that come with harnessing the power of the sun. From residential to commercial projects, we bring unparalleled expertise to every solar venture.
                            Comprehensive Solutions: We offer a comprehensive range of solar solutions, ensuring that our clients have access to the latest technologies and innovations. Whether you are embarking on a new solar project or looking to upgrade your existing system, Eco Amp Solutions has the expertise and products to meet your needs.

                        </Title>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <img alt="Service 1" src={'/assets/service1.png'} style={{ width: '80%', borderRadius: '5%', marginTop: '120px' }} />
                    </Col>
                    
                </Row>
                <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <img alt="Service 1" src={'/assets/illuminate.jpg'} style={{ width: '80%', borderRadius: '5%', marginTop: '80px' }} />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Title level={3} style={{ color: 'orange', fontSize: '35px' }}>
                            OUR VISION AND MISSION
                        </Title>
                        <div style={{ width: '100px' }}>
                            <Divider style={{ borderTop: "5px solid orange", width: 'fit-content' }} />
                        </div>
                        <Title level={4} >INVERT, ILLUMINATE, AND INSPIRE</Title>
                        <Title level={5} style={{ textAlign: 'justify' }}>
                        Join us on the solar journey and experience the transformative power of sustainable energy. At Eco Amp Solutions, we invite you to Invert, Illuminate & Inspire. Explore our range of products and services, and let's create a greener, cleaner future together.
                        </Title>
                        <Title level={5} style={{ textAlign: 'justify' }}>
                        Embark on a sustainable and renewable energy journey with Eco Amp Solutions. As your trusted partner in solar energy, we are here to transform your vision into reality. Explore our range of products and services, and let's create a greener, cleaner future together.

                        </Title>
                    </Col>
                    
                </Row>
            </div>
        </>
    );
}
