import { Link } from 'react-router-dom';
import { Layout, Menu, Row, Col } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ background: 'rgb(37 32 27)', marginTop: '50px' }}>
      <Row justify="space-between" align="middle">
        <Col xs={24} sm={12} md={12} lg={12} style={{ padding: '0'}}>
          {/* Your logo here */}
          <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <img src="/assets/ecoLogo.png" alt="Your Logo" height={140} width={240} />
            {/* Copyright */}
            <span style={{ marginLeft: '30px', color: 'orange'}}>&copy; {new Date().getFullYear()} Eco Amp Solutions</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Menu mode="horizontal" theme="light" style={{ backgroundColor: 'rgb(37 32 27)', color: 'orange'}}>
            <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="about">
                <Link to="/about">About</Link>
              </Menu.Item>
              <Menu.Item key="contact">
                <Link to="/contact">Contact</Link>
              </Menu.Item>
              <Menu.Item key="products">
                <Link to="/products">Products</Link>
              </Menu.Item>
            </Menu>
          </div>
          {/* Social media links */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <a href="/"><FacebookOutlined style={{ fontSize: '24px', marginRight: "10px", color: 'orange' }} /></a>
              <a href="/"><TwitterOutlined style={{ fontSize: '24px',  marginRight: "10px", color: 'orange' }} /></a>
              <a href="/"><InstagramOutlined style={{ fontSize: '24px',  marginRight: "10px", color: 'orange' }} /></a>
              <a href="/"><LinkedinOutlined style={{ fontSize: '24px', color: 'orange' }} /></a>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* Made by */}
            <span style={{marginTop: '20px', color: 'orange'}}>Made by Innovex</span>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
