import { Carousel } from "antd";
import { useState } from "react";
import '../../styles/carousel.css'

export default function CarouselComponent({ images }) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleBeforeChange = (from, to) => {
        setCurrentSlide(to);
    };

    return (
        <div>
            <Carousel beforeChange={handleBeforeChange}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img
                            alt={`Carousel ${index + 1}`}
                            src={image.src}
                            className="carouselImages"
                        />
                    </div>
                ))}
            </Carousel>
            {currentSlide === 0 && (
                <div className="carouselText">
                    <h1>Eco Amp Solutions</h1>
                    {/* Add any other text or components here */}
                </div>
            )}
        </div>
    );
}
