import ProductsNavbar from "../Navbar/ProductsNavbar";
import { Typography, Card, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CarouselComponent from "../Carousel/Carousel";
import { productsData } from './ProductsData'; // Assuming productsData is exported from Products.js
import '../../styles/allproducts.css'
const { Title, Text } = Typography;



const Products = () => {
    const imagesForCarousel1 = [
        { src: '/assets/carousel.png' },
        { src: '/assets/aboutImage.png' },
        { src: '/assets/carousel2.png' },
        { src: '/assets/carousel3.png' },
        { src: '/assets/carousel4.png' },
        { src: '/assets/carousel5.png' },

    ];

    return (
        <>
            <ProductsNavbar />
            <CarouselComponent images={imagesForCarousel1} />
            <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Title style={{ display: 'flex', alignItems: 'left', fontSize: '50px', color: '#fa8c16', marginLeft: '300px' }}>
                    Our Products
                </Title>
            </div>
            <Divider></Divider>
            {productsData.map((product, index) => (
                <div key={index} className="product-category" style={{ padding: '0 20px' }}>
                    {/* <Title level={3} style={{ textAlign: 'center', margin: '50px', fontSize: '30px' }}>{product.title}</Title> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text style={{ display: 'flex', alignItems: 'center', fontSize: '35px', color: '#fa8c16' }}>
                            {product.title}
                        </Text>
                    </div>
                    <Divider></Divider>
                    <Row gutter={[16, 16]}>
                        {product.items.map((item, idx) => (
                            <Col key={idx} xs={24} sm={12} md={8} lg={8}>
                                <Link to={`/products/${item.name}`} className="product-card">
                                    <Card
                                        hoverable
                                        cover={<img alt={item.name} src={item.image} style={{ height: '400px', maxWidth: '100%', objectFit: 'cover' }} className="product-image" />}
                                    >
                                        <Card.Meta
                                            title={item.name}
                                            className="custom-card-meta"
                                        />
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </>
    );
};

export default Products;
