import CarouselComponent from '../Carousel/Carousel.js';
import NavBarComponent from '../Navbar/Navbar.js';
import { ConfigProvider, Divider, Space } from 'antd';
import { Typography } from 'antd';
import Services from '../Services/Services.js'
import Intro from '../Intro/Intro.js';
import { motion } from 'framer-motion';


const { Title } = Typography;

function Home() {
  const imagesForCarousel1 = [
    // { src: '/assets/illuminate.jpg' },
    { src: '/assets/illuminate2.jpg' },
    { src: '/assets/carouselnew1.png' },
    { src: '/assets/carouselnew2.png' },
    { src: '/assets/carouselnew3.png' },
    // { src: '/assets/carousel5.png' },

  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FFA500',
          colorLinkActive: '#FFA500'
        },
      }}
    >
      <NavBarComponent />
      <CarouselComponent images={imagesForCarousel1} />
      <Divider style={{ margin: '16px 0' }} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', backgroundColor: '#ffffff' }}>
        <Title level={2} style={{ textAlign: 'center', maxWidth: '80%', fontSize: 'clamp(20px, 5vw, 40px)' }}>
          Cutting-Edge <span style={{ color: 'orange' }}> Solar Energy</span> Solutions for a Sustainable Future
        </Title>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <motion.div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '15vh',
          marginTop: '20px',
        }}
      >
        <Space direction="vertical" align="center">
          <motion.div>
            <Title level={2} style={{ display: 'flex', alignItems: 'center', fontSize: '50px', color: 'orange' }}>
              Who are We?
            </Title>
          </motion.div>
        </Space>
      </motion.div>
      <Divider style={{ margin: '16px 0' }} />
      <motion.div>
        <Intro></Intro>
      </motion.div>
      <Divider style={{ margin: '16px 0' }} />
      <motion.div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '15vh',
          marginTop: '20px',
        }}
      >
        <Space direction="vertical" align="center">
          <motion.div>
            <Title level={2} style={{ display: 'flex', alignItems: 'center', fontSize: '50px', color: 'orange' }}>
              Our Services
            </Title>
          </motion.div>
        </Space>
      </motion.div>
      <Divider style={{ margin: '16px 0' }} />
      <motion.div>
        <Services></Services>
      </motion.div>
    </ConfigProvider>
  );
}

export default Home;
