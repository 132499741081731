export const productsData = [
    {
        title: 'Commercial and Industrial',
        items: [
            {
                name: 'SG110CX',
                image: '/assets/productimages/SG110CX.png',
                shortDescription: 'Multi-MPPT String Inverter for 1000Vdc System',
                numberOfImages: 4,
                detailsGrid: {
                    "HIGH YIELD": ['9 MPPTs with max. efficiency 98.7%', 'Compatible with bifacial module', 'Built-in PID recovery function'],
                    "SMART O&M": ['Touch free commissioning and remote firmware upgrade', 'Smart IV Curve diagnosis', 'Fuse free design with smart string current monitoring'],
                    "SAVED INVESTMENT": ['Compatible with Al and Cu AC cables', 'DC 2 in 1 connection enabled', 'Q at night function'],
                    "PROVEN SAFETY": ['IP66 and C5 anti-corrosion', 'Type II SPD for both DC and AC', 'Compliant with global safety and grid code']
                }
            },
            {
                name: 'SG125CX-P2',
                image: '/assets/productimages/SG125CX-P2.png',
                shortDescription: 'Multi-MPPT String Inverter for 1000Vdc System',
                numberOfImages: 3,
                detailsGrid: {
                    "HIGH YIELD": ['DC 15A current input, compatiable with over 500W+ PV module', 'Dynamic shading optimization mode', 'Built-in PID recovery function'],
                    "SMART O&M": ['Key component diagnosis and protection', 'Smart IV Curve diagnosis', 'Grid fault record function, easy for remote O&M'],
                    "LOWER INVESTMENT": ['Easy to handle thanks to 34% weight reduced', 'Plug and Play with Buckle Design'],
                    "PROVEN SAFETY": ['IP66 and C5 anti-corrosion', 'DC Type I+II SPD, AC Type II SPD', 'Support AFCI 2.0 function']
                }
            },
            {
                name: 'SG33CX-P2',
                image: '/assets/productimages/SG33CX-P2.png',
                shortDescription: 'Multi-MPPT String Inverter for 1000Vdc System',
                numberOfImages: 3,
                detailsGrid: {
                    "HIGH YIELD": ['DC 15A current input, compatiable with over 500W+ PV module', 'Dynamic shading optimization mode', 'Built-in PID recovery function'],
                    "SMART O&M": ['Key component diagnosis and protection', 'Smart IV Curve diagnosis', 'Grid fault record function, easy for remote O&M'],
                    "LOWER INVESTMENT": ['Easy to handle thanks to 34% weight reduced', 'Plug and Play with Buckle Design'],
                    "PROVEN SAFETY": ['IP66 and C5 anti-corrosion', 'DC Type I+II SPD, AC Type II SPD', 'Support AFCI 2.0 function']
                }
            },
            {
                name: 'SG50CX-P2',
                image: '/assets/productimages/SG50CX-P2.png',
                shortDescription: 'Multi-MPPT String Inverter for 1000Vdc System',
                numberOfImages: 3,
                detailsGrid: {
                    "HIGH YIELD": ['DC 15A current input, compatiable with over 500W+ PV module', 'Dynamic shading optimization mode', 'Built-in PID recovery function'],
                    "SMART O&M": ['Key component diagnosis and protection', 'Smart IV Curve diagnosis', 'Grid fault record function, easy for remote O&M'],
                    "LOWER INVESTMENT": ['Easy to handle thanks to 34% weight reduced', 'Plug and Play with Buckle Design'],
                    "PROVEN SAFETY": ['IP66 and C5 anti-corrosion', 'DC Type I+II SPD, AC Type II SPD', 'Support AFCI 2.0 function']
                }
            },
        ]
    },
    {
        title: 'Monitoring Solutions',
        items: [
            {
                name: 'COM100D',
                image: '/assets/productimages/COM100D.png',
                shortDescription: 'Smart Communication Box',
                numberOfImages: 4,
                detailsGrid: {
                    "SMART AND FLEXIBLE": ['Support of RS485, Ethernet and WiFi communication', 'Support of energy meter, meteo station, sensors and other equipment'],
                    "CONVENIENT O&M": ['Inverter batch parameter settings and firmware updates', 'PV-Plant maintenance via remote Web access for optimized OPEX', 'Active and reactive power control', 'Local monitoring'],
                    "EASY OPERATION": ['Night light for maintenance', 'Robust enclosure, easy to install'],
                },
            },
            {
                name: 'EMU200A',
                image: '/assets/productimages/EMU200A.png',
                shortDescription: 'Energy Management Unit',
                numberOfImages: 0,
                detailsGrid: {
                    "FLEXIBLE NETWORKING": ['Support of RS485, Ethernet and WiFi communication', 'Support of energy meter, meteo station, sensors and other equipment'],
                    "CONVENIENT O&M": ['Inverter batch parameter settings and firmware updates', 'PV-Plant maintenance via remote Web access for optimized OPEX', 'Active and reactive power control, eactive power 30ms fast dispatch with GOOSE protocol'],
                    "SAFE RELIABLE": ['Electrical isolation and SPD for every port', 'IP66 protection'],
                },
            },
            {
                name: 'EyeM4',
                image: '/assets/productimages/EyeM4.png',
                shortDescription: 'Wireless Communication Module for Multiple Inverter',
                numberOfImages: 3,
                detailsGrid: {
                    "SMART AND FLEXIBLE": ['One-click access to iSolarCloud', 'One module can manage up to 10 inverters for remote maintenance and control', 'Plug and play, easy installation'],
                    "CONVENIENT O&M": ['Built-in Web server for monitoring and configuration, by PC or smartphone browser; no App required', 'Support of plant maintenance by remote Web access, optimized OPEX', 'Support of local and remote parameter setting and firmware updates'],
                },
            },
            {
                name: 'EyeS4',
                image: '/assets/productimages/EyeS4.png',
                shortDescription: 'Wireless Communication Module',
                numberOfImages: 1,
                detailsGrid: {
                    "SMART AND FLEXIBLE": ['Covering all regions with 4G signals', 'Full online monitoring, remote FW update and parameter settings'],
                    "SIMPLE AND EFFICIENT": ['Plug and play, no configuration needed, inverter instantly online', 'Local maintenance with Bluetooth connection'],
                    "SAFE AND RELIABLE": ['Fast and accurate, real time upload of fault data', 'Reliable 4G monitoring of single inverter']
                },
            },
            {
                name: 'WiFi',
                image: '/assets/productimages/WiFi.png',
                shortDescription: 'Wireless Communication Module',
                numberOfImages: 2,
                detailsGrid: {
                    "SMART AND FLEXIBLE": ['Supporting mainstream WLAN networking protocols, with favourable compatibility'],
                    "SIMPLE AND EFFICIENT": ['Supporting remote operation and maintenance functions including remote upgrading, parameter setting', 'Supporting direct connection configuration with APP, quickly and easily', 'Plug and play, quick installation'],
                    "SAFE AND RELIABLE": ['Professional design in wireless communication, and high quality signal', 'IP65, wide temperature range']
                },
            },
            {
                name: 'WiNet-S',
                image: '/assets/productimages/WiNet-S.png',
                shortDescription: 'LAN Communication Module',
                numberOfImages: 3,
                detailsGrid: {
                    "SMART AND FLEXIBLE": ['WLAN or Ethernet, flexible compatibility of plant networking, one-click access to iSolarCloud', 'Automatic network configuration with DHCP, transmission without configuration', 'Free WLAN configuration, easy and time saving'],
                    "SIMPLE AND EFFICIENT": ['Plug and play, quick installation', 'Data interval in seconds, quick glance for what you want', 'Support of Smart IV Curve Diagnosis', 'Support of local and remote parameter setting and firmware updates'],
                    "SAFE AND RELIABLE": ['Password and encrypted transmission for data protection', 'IP66, wide temperature range']
                },
            },
            {
                name: 'Zero-Export Device (CX series without CT)',
                image: '/assets/productimages/Zero-Export Device (CX series without CT).png',
                shortDescription: 'Three-phase Smart Energy Meter',
                numberOfImages: 3,
                detailsGrid: {}
            },
            {
                name: 'Zero-Export Device (RS_RT_Series)',
                image: '/assets/productimages/Zero-Export Device (RS_RT_Series).png',
                shortDescription: 'Three-phase Smart Energy Meter',
                numberOfImages: 3,
                detailsGrid: {}
            },
        ]
    },
    {
        title: 'Residential',
        items: [
            {
                name: 'SG3.0RS-L',
                image: '/assets/productimages/SG3.0RS-L.png',
                shortDescription: 'Single-MPPT String Inverter for 600 Vdc System',
                numberOfImages: 4,
                detailsGrid: {
                    "HIGH YIELD": ['Compatible with high power PV modules', 'Lower startup & wider MPPT voltage range', 'Built-in smart PID Zero function'],
                    "SAFE AND RELIABLE": ['Built-in Type II DC & AC SPD', 'IP65, indoor & outdoor installation'],
                    "USER FRIENDLY SETUP": ['Plug and play installation', 'One-click access to iSolarCloud monitoring platform', 'Light and compact ( A4 Size ) with optimized heat dissipation design'],
                    "SMART MANAGEMENT": ['Real time data ( 10 seconds refresh sample )', 'Remote firmware updates']
                }
            },
            {
                name: 'SG15 20RT',
                image: '/assets/productimages/SG15 20RT.png',
                shortDescription: 'Multi-MPPT String Inverter for 1000 Vdc System',
                numberOfImages: 4,
                detailsGrid: {
                    "HIGH YIELD": ['Lower startup & wider MPPT voltage', 'Compatible with bifacial modules'],
                    "SMART MANAGEMENT": ['Smart IV curve scanning', 'Remote firmware updates'],
                    "SAFE AND DURABLE": ['Built-in Type II DC&AC SPD', 'High anti-corrosion rating C5'],
                    "EASY AND USER FRIENDLY": ['21kg compact design', 'Fast and easy commissioning via App']
                }
            },
        ]
    },
    {
        title: 'Utility',
        items: [
            {
                name: 'SG320HX',
                image: '/assets/productimages/SG320HX.png',
                shortDescription: 'Multi-MPPT String Inverter for 1500 Vdc System',
                numberOfImages: 2,
                detailsGrid: {
                    "HIGH YIELD": ['Up to 16 MPPT with 99% efficiency', '40A MPPT compatible with 500Wp+ module', 'DC 2 in 1 connection'],
                    "LOW COST": ['Compatible with Al and Cu AC cables', 'Less than 30ms reactive power response', 'PLC communication to save the cable cost'],
                    "SMART O&M": ['Touch free commissioning and remote firmware upgrade', 'Smart string detection and IV scanning', 'Fuse free design with smart string current monitoring'],
                    "PROVEN SAFTEY": ['IP66 protection and C5 anti-corrosion grade', 'Type II SPD for both DC and AC']
                }
            },
            {
                name: 'SG320HX-20',
                image: '/assets/productimages/SG320HX-20.png',
                shortDescription: 'Multi-MPPT String Inverter for 1500 Vdc System',
                numberOfImages: 3,
                detailsGrid: {
                    "HIGH YIELD": ['Up to 6 MPPTs with max. efficiency 99%', '75A per MPPT, adapt to different PV module', 'Max 30 inputs, adapt to different DC/AC ratios'],
                    "LOW COST": ['Q at night function, save investment', 'Power line communication (PLC)', 'Smart IV Curve diagnosis, active O&M', 'Integrated tracking power and communication interface, saving cable and construction costs'],
                    "PROVEN SAFETY": ['Intelligent DC switch, automatically cut off the fault', '24h real-time AC and DC insulation monitoring', 'IP66 protection, C5 design, adapt to all kinds of harsh environment', 'Smart-cooled and dedusted fan with IP68 protection, low temperature rise, long lifecycle'],
                    "GRID-FRIENDLY": ['SCR≥1.1 stable operation in extremely weak grid', 'Fastest Reactive power response time 20ms', 'Compliant with global grid code']
                }
            }
        ]
    },
]