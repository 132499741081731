import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

export default function Services() {
    return (
        <div style={{ maxWidth: '1100px', margin: '0 auto', padding: '0 20px', marginBottom: '40px' }}>
            <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <img alt="Service 1" src={'/assets/service1.png'} style={{ width: '80%', borderRadius: '5%' }} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Title level={3} style={{ color: 'orange' }}>
                    SOLAR PV INVERTERS: 
                    </Title>
                    <Title level={4} >EXPLORE OUR RANGE OF SOLAR PV INVERTERS</Title>
                    <Title level={5} style={{ textAlign: 'justify' }}>
                    Explore our range of solar PV inverters, carefully selected to deliver optimal performance and efficiency. Whether you require inverters for residential, commercial, or industrial applications, Eco Amp Solutions has the right solution for you.
                    </Title>
                </Col>
            </Row>
            <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Title level={3} style={{ color: 'orange' }}>
                    CONSULTATION AND SUPPORT: 
                    </Title>
                    <Title level={4} >CONSULTATION AND SUPPORT</Title>
                    <Title level={5} style={{ textAlign: 'justify' }}>
                    Our team of experts are dedicated to providing personalized consultation and support. From project planning to post-installation assistance, we are committed to ensuring a seamless and successful solar experience for our clients.
                    </Title>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <img alt="Service 1" src={'/assets/consultImage.png'} style={{ marginLeft: '10px', width: '80%', borderRadius: '5%' }} />
                </Col>

            </Row>
            <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <img alt="Service 1" src={'/assets/education.png'} style={{ marginLeft: '10px', width: '80%', borderRadius: '5%' }} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Title level={3} style={{ color: 'orange' }}>
                    TRAINING AND EDUCATION: 
                    </Title>
                    <Title level={4} >EMPOWERING CLIENTS WITH KNOWLEDGE</Title>
                    <Title level={5} style={{ textAlign: 'justify' }}>
                    Take advantage of our training and education programs, designed to keep you informed about the latest advancements in solar technology and best practices.
                    </Title>
                </Col>

            </Row>
        </div>
    );
}
