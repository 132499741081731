import { Typography, Row, Col } from 'antd';

const { Title } = Typography;

const Intro = () => (
    <div style={{ maxWidth: '1100px', margin: '0 auto', padding: '0 20px' }}>
        <Row gutter={[16, 16]} justify="center">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <div style={{ textAlign: 'justify' }}>
                    <Title level={5}>
                        Welcome to Eco Amp Solutions. Invert, Illuminate & Inspire with Leading Solar PV Inverters.
                        At Eco Amp Solutions, we take pride in being a trusted name in the solar energy industry, specializing in the distribution of high-quality Solar PV inverters. With a wealth of experience in solar installations, applications, products, and services, we have evolved into a leading dealer for Sungrow PV inverter manufacturer.

                    </Title>
                    <Title level={5}>
                        Expertise in Solar Installations: With years of hands-on experience in solar installations, our team at Eco Amp Solutions understands the unique challenges and opportunities that come with harnessing the power of the sun. From residential to commercial projects, we bring unparalleled expertise to every solar venture.
                        Comprehensive Solutions: We offer a comprehensive range of solar solutions, ensuring that our clients have access to the latest technologies and innovations. Whether you are embarking on a new solar project or looking to upgrade your existing system, Eco Amp Solutions has the expertise and products to meet your needs.

                    </Title>
                    <Title level={5}>
                        As a testament to our commitment to quality, Eco Amp Solutions has established partnerships with tier-1 PV inverter manufacturers. This strategic collaboration allows us to provide our clients with inverters that meet the highest industry standards for performance, reliability, and efficiency. We believe in offering cutting-edge solutions that empower our clients to maximize their solar energy potential.
                    </Title>
                </div>
            </Col>
        </Row>
    </div>
);

export default Intro;
